import React from "react"

/*
 * The reason we use Global State instead of Component State is that
 * when the user clicks something on the main page and then clicks back,
 * we don't want to reset the user's scroll position. If we don't maintain
 * state, then we will "lose" some of the items when the user clicks
 * back and the state resets, which obviously resets scroll position as well.
 */
export const GlobalStateContext = React.createContext({
    cursor: 0, /* Which page infinite scroll should fetch next. */
    useInfiniteScroll: true, /* Toggle between pagination and inf. scroll for this demo & fallback in case of error. */
    articles: [],
    isInitializing: () => { return true },
    updateState: () => {},
    hasMore: () => {},
    loadMore: () => {}
});

export class GlobalState extends React.Component {

    constructor(props) {
        super(props)

        this.loadMore = this.loadMore.bind(this)
        this.hasMore = this.hasMore.bind(this)
        this.updateState = this.updateState.bind(this)
        this.isInitializing = this.isInitializing.bind(this)
        
        /* State also contains metadata for items, e.g. state["page81"] (only contains keys for _received_ metadata) */
        this.state = {
            cursor: 0,
            startingPage: "",
            stopUpdating: false,
            journey: [],
            articles: [],
            useInfiniteScroll: true,
            isInitializing: this.isInitializing,
            updateState: this.updateState,
            hasMore: this.hasMore,
            loadMore: this.loadMore
        }
    }

    isInitializing = () => {
        return (this.state.cursor === 0)
    }

    updateState = (mergeableStateObject) => {
        this.setState(mergeableStateObject)
    }

    loadMore = () => {
        
        const pageNum = this.state.cursor
        var page_slug_to_load = this.state.startingPage
        if(pageNum > 1){
            // get the last page
            // console.log(this.state.articles.length, this.state.articles[this.state.articles.length-1])
            page_slug_to_load = this.state.articles[this.state.articles.length-1]?.slug
        }

        console.log("Fetching metadata for page " + page_slug_to_load, " using ", this.state)

        this.setState(state => ({ cursor: this.state.articles.length+1 })) // TODO: make sure this is guaranteed to set state before another loadMore may be able to fire!


        if( !this.state.journey.includes("/"+page_slug_to_load+"/")) {
            return <></>
        }
        console.log("fetching!!!!")
        // get the next page from api
        fetch(process.env.RECOMMENDATION_ENGINE_URL+`?sid=${process.env.GATSBY_SITE_ID}&pid=${process.env.GATSBY_PROJECT_ID}&page=${page_slug_to_load}`
        ,{
            method: 'POST',
            headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            },
            body : JSON.stringify({
                'first': this.state.startingPage,
                'ip': this.state?.userIP,
                'persona': this.state?.persona,
                'journey': this.state?.journey
            })
        })
        .then(res => res.json())
        .then(
          res => {
            this.setState({
                ["page"+pageNum]: res['recommendations']
            })

          },
          error => {
            this.setState({
              // Fallback to Pagination on error.
              useInfiniteScroll: false 
            })
          }
        )

    }

    hasMore = (pageContext) => {
        if (!this.state.useInfiniteScroll) return false
        if (this.isInitializing()) return true
        if (this.state.articles.length > 25) return false
        if (this.state.stopUpdating) return false
        return true // pageContext.countPages
    }

    render() {
        return (
            <GlobalStateContext.Provider value={this.state}>
                {this.props.children}
            </GlobalStateContext.Provider>
        )
    }

}